import React, { useState, useContext, createContext, useEffect } from "react";
// import { decrypt, encrypt } from "../util/encrypt";

import { getAuth } from "firebase/auth";
import { Account } from "../models/account";
import { useInterval } from "src/util/timer";

interface AuthContextProps {
  authUser: Account | null;
  setAuthUser: (acc: Account) => void;
  setToken: (t: string) => void;
  setRefreshToken: (t: string) => void;
  token: string | null;
  removeAuthUser: () => void;
  removeToken: () => void;
  checkPermission: (p: string) => boolean;
  setRememberMe: (e: string) => void;
  getPermissions: () => any;
  rememberMe: string | null;
  authName: string | null;
  setAuthName: (e: string) => void;
  getAuth: () => any;
}
const initalState: AuthContextProps = {
  authUser: null,
  setAuthUser: (acc: Account) => { },
  setToken: (t: string) => { },
  setRefreshToken: (t: string) => { },
  token: null,
  removeAuthUser: () => { },
  removeToken: () => { },
  checkPermission: (p: string) => false,
  setRememberMe: (e: string) => { },
  getPermissions: () => { },
  rememberMe: null,
  authName: null,
  setAuthName: (e: string) => { },
  getAuth: () => { },
};
const AuthContext = createContext(initalState as AuthContextProps);
export const useAuthContext = () => useContext(AuthContext);
// This context provider is passed to any component requiring the context
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // const [localAuth, setlocalAuth] = useState(false);
  const [localToken, setlocalToken] = useState<string | null>(null);
  const [localRememberMe, setlocalRememberMe] = useState<string | null>(null);
  const [localUsername, setlocalUsername] = useState<string | null>("");
  useInterval(() => {
    const firebaseAuth = getAuth();
    firebaseAuth.currentUser?.getIdToken(true).then((idToken) => {
      setlocalToken(idToken);
    });
  }, 5 * 60 * 1000);

  const authUser: Account = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser") ?? "{}")
    : null;
  const token = localToken
    ? localToken
    : localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;
  const rememberMe =
    localRememberMe !== null
      ? localRememberMe
      : localStorage.getItem("rememberMe")
        ? localStorage.getItem("rememberMe")
        : null;
  const authName = localUsername
    ? localUsername
    : localStorage.getItem("username")
      ? localStorage.getItem("username")
      : null;

  const setPermission = (rolePermissions: string[]) => {
    if (rolePermissions) {
      localStorage.setItem("user-permissions", JSON.stringify(rolePermissions));
    }
  };

  const setAuthName = (e: string) => {
    if (rememberMe) {
      setlocalUsername(e);
      localStorage.setItem("username", e);
    }
  };

  const permissions = null;
  const getPermissions = () => {
    if (!permissions) {
      if (!localStorage.getItem("user-permissions")) {
        return;
      }

      return JSON.parse(localStorage.getItem("user-permissions") ?? "[]");
    } else {
      return permissions;
    }
  };
  const checkPermission = (p: string) => {
    const ps = getPermissions();
    if (ps) {
      if (ps?.includes("SUPER")) {
        return true;
      }
      return ps?.indexOf(p) > -1;
    }
    return false;
  };

  const setAuthUser = (acc: Account) => {
    console.log(acc.role?.permissions)
    setPermission(acc.role?.permissions);
    localStorage.setItem("authUser", JSON.stringify(acc));
  };

  const setToken = (t: string) => {
    localStorage.setItem("token", t);
    setlocalToken(t);
  };

  const setRefreshToken = (t: string) => {
    localStorage.setItem("refresh_token", t);
  };

  const removeToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  };
  const removeAuthUser = () => {
    localStorage.removeItem("authUser");
  };
  const setRememberMe = (e: string) => {
    localStorage.setItem("rememberMe", e);
    setlocalRememberMe(e);
  };

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        setToken,
        setRefreshToken,
        token,
        removeAuthUser,
        removeToken,
        checkPermission,
        setRememberMe,
        getPermissions,
        rememberMe,
        authName,
        setAuthName,
        getAuth: getAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
